import type { ParamsDictionary } from "express-serve-static-core";
import { StatusCodes } from "http-status-codes";
import "isomorphic-fetch";
import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";
import merge from "lodash/merge";
import qs from "qs";

import { RequestBodyParams, RequestQueryParams } from "./types";
import { SurfaceableError } from "./utils/SurfaceableError";

export async function throws<T>(responsePromise: Promise<AppReqResponse<T>>): Promise<SuccessRes<T>> {
  const response = await responsePromise;
  if (response.type === "error") {
    throw new SurfaceableError({ message: response.message, status: response.status });
  }
  return response;
}

export type ResType = "success" | "error";

interface ResWrapper {
  type: ResType;
}

interface ParsedResError {
  status: number;
  message: string;
}

export interface SuccessRes<T> extends ResWrapper {
  type: "success";
  body: T;
  headers?: { [key: string]: string };
}

export interface FailureRes extends ParsedResError, ResWrapper {
  type: "error";
  status: number;
  message: string;
  accountStatus?: string;
}

export type AppReqResponse<ResBody> = SuccessRes<ResBody> | FailureRes;

const defaultConfig: RequestInit = {
  credentials: "omit",
  referrerPolicy: "origin-when-cross-origin",
  mode: "cors",
  cache: "default",
};

function req<
  ReqBody extends RequestBodyParams,
  ResBody,
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends RequestQueryParams = {},
>(
  endpoint: string,
  { params, query, ...requestConfig }: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>
): Promise<AppReqResponse<ResBody>> {
  // handling attaching of request body
  const requestConfigWithFinalizedBody: RequestInit = {
    ...defaultConfig,
    ...requestConfig,
  };
  if (requestConfig.reqBody) {
    if (isPlainObject(requestConfig.reqBody)) {
      requestConfigWithFinalizedBody.headers = {
        ...requestConfigWithFinalizedBody.headers,
        "Content-Type": "application/json", // this is needed for Express to know how to parse
      };
      requestConfigWithFinalizedBody.body = JSON.stringify(requestConfig.reqBody);
    } else if (typeof requestConfig.reqBody === "string") {
      requestConfigWithFinalizedBody.headers = {
        ...requestConfigWithFinalizedBody.headers,
        "Content-Type": "text/plain", // this is needed for Express to know how to parse
      };
      requestConfigWithFinalizedBody.body = requestConfig.reqBody;
    } else {
      // confused as to why I need to cheat here... no big deal though
      requestConfigWithFinalizedBody.body = requestConfig.reqBody as any;
    }
  }

  // handle applying params
  let endpointWithParams: string;
  if (params) {
    endpointWithParams = Object.keys(params).reduce((accum: string, paramName: string) => {
      return accum.replace(`:${paramName}`, encodeURIComponent(params[paramName]) as string);
    }, endpoint);
  } else {
    endpointWithParams = endpoint;
  }

  // handle applying query string
  let endpointWithQueryString: string;
  if (!query || Object.keys(query).length === 0) {
    endpointWithQueryString = endpointWithParams;
  } else {
    endpointWithQueryString = `${endpointWithParams}${qs.stringify(query, { addQueryPrefix: true })}`;
  }

  return fetch(endpointWithQueryString, {
    ...requestConfigWithFinalizedBody,
  })
    .then(res => {
      if (!res.ok) {
        return res
          .text()
          .then(errorText => {
            let parsedJson: ParsedResError | undefined;
            try {
              parsedJson = JSON.parse(errorText) as ParsedResError;
            } catch {}

            // pass off to failure handling
            throw {
              status: parsedJson?.status || res.status,
              message: parsedJson?.message || errorText,
            };
          })
          .catch(error => {
            // sloppy but everything else screwed up Typescript compiler
            if (error.message && error.status) {
              throw {
                status: error.status,
                message: error.message,
              };
            }
            throw res;
          });
      }

      const contentType = res.headers.get("content-type");
      if (contentType) {
        if (contentType.includes("application/json")) {
          return res.json().then(
            responseJson =>
              ({
                type: "success",
                body: responseJson as unknown as ResBody,
              }) as SuccessRes<ResBody>
          );
        } else if (contentType.includes("application/text") || contentType.includes("text/plain")) {
          return res.text().then(
            responseText =>
              ({
                type: "success",
                body: responseText as unknown as ResBody,
              }) as SuccessRes<ResBody>
          );
        } else if (contentType.includes("text/csv")) {
          return res.text().then(
            responseText =>
              ({
                type: "success",
                body: responseText as unknown as ResBody,
              }) as SuccessRes<ResBody>
          );
        }
      }

      if (contentType) {
        const errorContentPromise = contentType.includes("text/html") ? res.text() : Promise.resolve("");
        return errorContentPromise.then(errorContent => {
          const errorMessage = `got unhandled content-type header in response from ${endpointWithQueryString}, content-type: ${contentType}${
            errorContent ? `\n\n${errorContent}` : ""
          }`;
          console.error(errorMessage);
          throw {
            status: StatusCodes.EXPECTATION_FAILED,
            message: errorMessage,
          };
        });
      }

      return {
        type: "success",
        body: undefined as unknown as ResBody,
        headers: [...res.headers.entries()].reduce(
          (acc, [k, v]) => ({ ...acc, [k]: v }),
          {} as { [key: string]: string }
        ),
      } as SuccessRes<ResBody>;
    })
    .catch(error => {
      const message = (error?.message as string) || "";
      const status = Number.parseInt(error?.status, 10) || 400;

      return {
        type: "error" as ResType,
        status,
        message,
      } as FailureRes;
    });
}

function getReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends RequestQueryParams = {}>(
  endpoint: string,
  requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {}
) {
  return req<undefined, ResBody, ReqParams, ReqQuery>(endpoint, {
    ...requestConfig,
    method: "GET",
  });
}
function putReq<
  ReqBody extends RequestBodyParams,
  ResBody,
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends RequestQueryParams = {},
>(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
  return req<ReqBody, ResBody, ReqParams, ReqQuery>(endpoint, {
    ...requestConfig,
    method: "PUT",
  });
}
function postReq<
  ReqBody extends RequestBodyParams,
  ResBody,
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends RequestQueryParams = {},
>(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
  return req<ReqBody, ResBody, ReqParams, ReqQuery>(endpoint, {
    ...requestConfig,
    method: "POST",
  });
}
function deleteReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends RequestQueryParams = {}>(
  endpoint: string,
  requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {}
) {
  return req<undefined, ResBody, ReqParams, ReqQuery>(endpoint, {
    ...requestConfig,
    method: "DELETE",
  });
}

export type ReqMiddleware = <
  ReqBody extends RequestBodyParams,
  ReqParams extends ParamsDictionary,
  ReqQuery extends RequestQueryParams,
>(
  endpoint: string,
  requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>
) => TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>;

export class Req {
  constructor(prefix = "", requestConfig: TypedBodyRequestInit = {}, middlewares: ReqMiddleware[] = []) {
    this.prefix = prefix;
    this.requestConfig = requestConfig;
    this.middlewares = middlewares;
  }

  private readonly middlewares: ReqMiddleware[];
  private readonly requestConfig: RequestInit;
  private readonly prefix: string;

  getFinalRequestConfig = <
    ReqBody extends RequestBodyParams,
    ReqParams extends ParamsDictionary,
    ReqQuery extends RequestQueryParams,
  >(
    endpoint: string,
    requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}
  ) =>
    this.middlewares.reduce(
      (accum: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery>, currentMiddleware: ReqMiddleware) =>
        currentMiddleware(endpoint, accum),
      merge(cloneDeep(this.requestConfig), requestConfig)
    );

  getReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends RequestQueryParams = {}>(
    endpoint: string,
    requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {}
  ) {
    return getReq<ResBody, ReqParams, ReqQuery>(
      `${this.prefix}${endpoint}`,
      this.getFinalRequestConfig(endpoint, requestConfig)
    );
  }

  putReq<
    ReqBody extends RequestBodyParams,
    ResBody,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends RequestQueryParams = {},
  >(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
    return putReq<ReqBody, ResBody, ReqParams, ReqQuery>(
      `${this.prefix}${endpoint}`,
      this.getFinalRequestConfig(endpoint, requestConfig)
    );
  }

  postReq<
    ReqBody extends RequestBodyParams,
    ResBody,
    ReqParams extends ParamsDictionary = {},
    ReqQuery extends RequestQueryParams = {},
  >(endpoint: string, requestConfig: TypedBodyRequestInit<ReqBody, ReqParams, ReqQuery> = {}) {
    return postReq<ReqBody, ResBody, ReqParams, ReqQuery>(
      `${this.prefix}${endpoint}`,
      this.getFinalRequestConfig(endpoint, requestConfig)
    );
  }

  deleteReq<ResBody, ReqParams extends ParamsDictionary = {}, ReqQuery extends RequestQueryParams = {}>(
    endpoint: string,
    requestConfig: TypedBodyRequestNoReqBodyInit<ReqParams, ReqQuery> = {}
  ) {
    return deleteReq<ResBody, ReqParams, ReqQuery>(
      `${this.prefix}${endpoint}`,
      this.getFinalRequestConfig(endpoint, requestConfig)
    );
  }
}

// this interface is used to handle coercion of body from JS object to JSON string
export interface TypedBodyRequestInit<
  ReqBody extends {} | undefined = undefined,
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends {} = {},
> extends RequestInit {
  reqBody?: ReqBody;
  params?: ReqParams;
  query?: ReqQuery;
  body?: undefined;
}

export type TypedBodyRequestNoReqBodyInit<
  ReqParams extends ParamsDictionary = {},
  ReqQuery extends {} = {},
> = TypedBodyRequestInit<undefined, ReqParams, ReqQuery>;
