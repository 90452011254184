import { getSessionToken } from "@shopify/app-bridge/utilities";
import { SHOPIFY_HEADERS, VENDOR_API_HEADERS } from "@smartrr/shared/constants";
import { ReqMiddleware, TypedBodyRequestInit } from "@smartrr/shared/req";
import { TypedFrontendVendorApi } from "@smartrr/shared/typedVendorApi/TypedVendorRoutes";
import { getVendorBearerToken, makeBearerTokenHeaderObj } from "@smartrr/shared/utils/authToken";

import { getAppBridgeInstance } from "./getAppBridgeInstance";
import { getShopUrlFromGlobals } from "./shopify/getShopUrlFromGlobals";

const addSessionTokenToRequest = async <T extends TypedBodyRequestInit>(endpoint: string, requestConfig: T) => {
  if (window.location === window.parent.location || endpoint.includes("/auth/")) {
    // When sending request in regular (not embedded) mode or when sending request to authentication
    // routes, we don't need to append session tokens to the request
    return requestConfig;
  }

  const app = await getAppBridgeInstance();
  const sessionToken = await getSessionToken(app);

  return {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      [VENDOR_API_HEADERS.SHOPIFY_SESSION_TOKEN]: sessionToken,
    },
  };
};

const middlewares: ReqMiddleware[] = [
  (endpoint, requestConfig) => {
    const shopUrl = getShopUrlFromGlobals();

    return {
      ...requestConfig,
      headers: {
        [SHOPIFY_HEADERS.SHOP_DOMAIN]: shopUrl,
        ...requestConfig.headers,
        ...makeBearerTokenHeaderObj(getVendorBearerToken()),
      } as HeadersInit,
    };
  },
];
export const typedFrontendVendorApi = new TypedFrontendVendorApi(middlewares, addSessionTokenToRequest);
