import { IS_BROWSER } from "@smartrr/shared/constants";
import { getParsedQuery } from "@smartrr/shared/utils/frontendQueryUtils";
import { getFromLocalStorage, setInLocalStorage } from "@smartrr/shared/utils/localStorageAccess";

const hostUrlPropertyName = "host";

let hostFromQueryOrLocalStorageCache: string | undefined;
let hostFromQueryOrLocalStorageCacheIsSet: boolean;
export function getHostUrlFromGlobals(): string | undefined {
  if (hostFromQueryOrLocalStorageCacheIsSet) {
    return hostFromQueryOrLocalStorageCache;
  }

  if (IS_BROWSER) {
    const localStorageValue = getFromLocalStorage(hostUrlPropertyName);
    const fromQueryString = getParsedQuery()[hostUrlPropertyName];
    hostFromQueryOrLocalStorageCache =
      // this order is important, query can override all
      (typeof fromQueryString === "string" ? fromQueryString : undefined) || localStorageValue || undefined;

    if (!localStorageValue && hostFromQueryOrLocalStorageCache) {
      setInLocalStorage(hostUrlPropertyName, hostFromQueryOrLocalStorageCache);
    }
  }

  hostFromQueryOrLocalStorageCacheIsSet = true;
  return hostFromQueryOrLocalStorageCache;
}
