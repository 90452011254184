import { ClientApplication, createApp } from "@shopify/app-bridge";
import { getAppApiKey } from "@smartrr/shared/constants";
import { getFromLocalStorage, setInLocalStorage } from "@smartrr/shared/utils/localStorageAccess";
import config from "@vendor-app/config";

import { getHostUrlFromGlobals } from "./shopify/getHostUrlFromGlobals";
import { typedFrontendVendorApi } from "./typedFrontendVendorApi";

export async function getAppBridgeInstance(): Promise<ClientApplication<any>> {
  const hostFromGlobals = getHostUrlFromGlobals();

  if (!hostFromGlobals) {
    throw new Error("shop not given in globals, needed for redirect");
  }

  await ensurePublicAppKey(hostFromGlobals);

  const apiKey =
    (hostFromGlobals && getFromLocalStorage(getAppApiKey(hostFromGlobals))) || config.shopify.clientId;

  if (!apiKey) {
    throw new Error("API key not given, needed for redirect");
  }

  return createApp({
    apiKey,
    host: hostFromGlobals,
  });
}

// This is needed to ensure app key is correct for custom apps
export function ensurePublicAppKey(shopUrl: string) {
  if (!getFromLocalStorage(getAppApiKey(shopUrl))) {
    return typedFrontendVendorApi.getReq("/auth/app-public-key").then(res => {
      if (res.type === "success") {
        setInLocalStorage(getAppApiKey(shopUrl), res.body.publicApiKey);
      }
    });
  }
}
